import banderaChina from './banderaChina.png'
import banderaCuba from './banderaCuba.png'
import banderaIran from './banderaIran.png'
import banderaTurkia from './banderaTurkia.png'
import banderaIndia from './banderaIndia.png'

export default {
  banderaChina: banderaChina,
  banderaCuba: banderaCuba,
  banderaIran: banderaIran,
  banderaTurkia: banderaTurkia,
  banderaIndia: banderaIndia
}
